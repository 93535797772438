import axios from "axios";

const BASE_URL = 'https://onlineteercounter.xyz/'

export const loginAPI = (body) =>
    axios.post(`${BASE_URL}login_user.php`,body);

export const signUpAPI = (body) =>
    axios.post(`${BASE_URL}register_user.php`,body);

export const createOTPAPI = (body) =>
    axios.post(`${BASE_URL}create_otp.php`,body);

export const resetPasswordAPI = (body) =>
    axios.post(`${BASE_URL}reset_user_password.php`,body);

export const gameListAPI = (body) =>
    axios.post(`${BASE_URL}get_all_games_meta.php`,body);

export const userDataAPI = (body) =>
    axios.post(`${BASE_URL}fetch_user_meta_data.php`,body);

export const teerGameResultAPI = (body) =>
    axios.post(`${BASE_URL}fetch_all_teer_results.php`,body);

export const contactUsAPI = (body) =>
    axios.post(`${BASE_URL}fetch_contact_us_meta.php`,body);