import React, { useEffect, useState } from "react";
import { API_KEY } from "../utils/constants";
import { userDataAPI } from "../api/api";
import SideBar from "./sideBar";
import { useNavigate } from "react-router-dom";

function Header({ title, isBack, isMenu, bgColor, noWallet, isHome, setWalletAmount }) {
  const navigate = useNavigate();
  const walletShow = noWallet ? noWallet : false;
  const [wallet, setWallet] = useState("");
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [open, setOpen] = useState(false);

  const getUserData = async () => {
    const userData = await localStorage.getItem("user");
    const user_uid = JSON.parse(userData)?.uid;

    const formData = new FormData();
    formData.append("__api_key__", API_KEY);
    formData.append("user_uid", user_uid);
    await userDataAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          setWallet(res.data.data.user_entity.wallet);
          setWalletAmount && setWalletAmount(res.data.data.user_entity.wallet)
          setName(res.data.data.user_entity.username);
          setProfile(res.data.data.user_entity.avatar);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    async function fetchData() {
      const userData = await localStorage.getItem("user");
      if (userData == null) {
        navigate("/");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div
      className="d-flex align-items-center justify-content-between header"
      style={{ backgroundColor:  "#6c62ff" }}
    >
      <div>
        {isMenu && (
          <img
            src="./images/menu_icon.png"
            style={{ width: 30, cursor: "pointer" }}
            alt="menu"
            onClick={() => setOpen(!open)}
          />
        )}
        {isBack && (
          <i
            className="bi bi-arrow-left text-white fs-2"
            onClick={() => navigate(-1)}
          />
        )}
      </div>
      {isHome ? (
        <div className="d-flex align-items-center justify-content-start col-7">
          <img
            src="./images/avatar.png"
            style={{
              width: 40,
              height: 40,
              borderRadius: 40,
              backgroundColor: "#fff",
              padding: 3,
            }}
            alt="profile"
          />
          <h5 className="m-0 text-white ms-3">{name}</h5>
        </div>
      ) : (
        <div>
          <h5 className="m-0 text-white">{title}</h5>
        </div>
      )}

      <div className="d-flex align-items-center">
      </div>
      <SideBar open={open} setOpen={setOpen} />
    </div>
  );
}

export default Header;
