import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/home';
import OTPVerify from './pages/otpVerify';
import SignUp from './pages/signUp';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Login from './pages/login';
import Profile from './pages/profile';
import KhanaparaResult from './pages/khanaparaResult';
import ShilongResult from './pages/shilongResult';
import ContactUs from './pages/contactUs';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import AboutUs from './pages/aboutUs';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/home' element={<Home />} />
        <Route path='/otp-verify' element={<OTPVerify />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/khanapara-result' element={<KhanaparaResult />} />
        <Route path='/shilong-result' element={<ShilongResult />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/about-us' element={<AboutUs />} />

      </Routes>
    </Router>
    <ToastContainer position='top-center' autoClose={3000} hideProgressBar />
    </>
  );
}

export default App;
