import React, { useEffect, useState } from "react";
import Header from "../components/header";
import GameCard from "../components/gameCard";
import { API_KEY } from "../utils/constants";
import { contactUsAPI, gameListAPI, sliderImageAPI } from "../api/api";
import AnimatedLoader from "../components/animatedLoader";

function Home() {
  const [gameList, setGameList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getGameList = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("__api_key__", API_KEY);
    await gameListAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          setGameList(res.data.data.standardGames);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getGameList();
  }, []);

  return (
    <div className="main-2" style={{ backgroundColor: "#6c62ff",  minHeight: '100vh' }}>
      <Header isMenu title={"name"} isHome />
      <div style={{width: '100%',}}>
        <div
          className="game-list-container pb-3 px-2 rounded-5 mt-3"
        >
          {gameList.map((item) => (
            <GameCard item={item} />
          ))}
        </div>
      </div>
      <AnimatedLoader isLoading={isLoading} />
    </div>
  );
}

export default Home;
