export const SideBarTab = [
    {
        name: 'Home',
        icon: './images/home.png',
        link: '/home'
    },
    {
        name: 'Profile',
        icon: './images/profile.png',
        link: '/profile'
    },
    {
        name: 'Khanapara Pervious Results',
        icon: './images/bid_history.png',
        link: '/khanapara-result'
    },
    {
        name: 'Shilong Previous Results',
        icon: './images/bid_history.png',
        link: '/shilong-result'
    },
    {
        name: 'Contact Us',
        icon: './images/contact_us.png',
        link: '/contact-us'
    },
    {
        name: 'About Us',
        icon: './images/info.png',
        link: '/about-us'
    },
    {
        name: 'Log Out',
        icon: './images/logout.png',
        link: '/'
    },
]