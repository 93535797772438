import React from "react";

const GameCard = ({ item }) => {

  return (
    <div className="bg-white px-3 py-4 game-card my-3" style={{borderRadius: 20}}>
      <div className="flex-row d-flex justify-content-between align-items-start">
        <div className="col-12 text-center">
          <h6 className="title m-0" >{item.name}</h6>
          <label className="text-danger m-0" style={{fontSize: 30}}>{item.result}</label>
        </div>

      </div>
    </div>
  );
};

export default GameCard;
